import moment from 'moment';

type LengthUnit = 'metric' | 'imperial';

export class Formatter {
  constructor(private userLanguageCode: string, private countryCode: string) {}

  interpolate(value: any, format: string | undefined) {
    if (format === 'distanceInMeters' && value) {
      return this.metersToCurrentLengthUnit(value);
    } else {
      const formattedDate = this.dateStringForValue(value, format);
      if (formattedDate) {
        return formattedDate;
      }
    }
    return value;
  }

  metersToCurrentLengthUnit(meters: number): string {
    if (this.lengthUnit() === 'imperial') {
      const feet = meters / 0.3048;
      // display feet for distances < than 100m
      if (meters < 100) {
        return Math.round(feet) + 'ft';
      } else {
        return Math.round(feet / 3) + 'yds';
      }
    } else {
      return Math.round(meters).toString() + 'm';
    }
  }

  dateStringForValue(value: any, format: any): string | null {
    if (value && format) {
      let date = moment(value, moment.ISO_8601, true);
      if (date.isValid()) {
        return date.locale(this.userLanguageCode).format(format);
      }
    }
    return null;
  }

  lengthUnit(): LengthUnit {
    const isImperial = ['US', 'MMR', 'LBR'].find(v => v.toLowerCase() === this.countryCode.toLowerCase());
    return isImperial ? 'imperial' : 'metric';
  }
}
