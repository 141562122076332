import React, { MouseEventHandler, PropsWithChildren } from "react";

interface Props {
  isActive?: boolean;
  onClick: MouseEventHandler;
}

export const ActionsCard = (props: PropsWithChildren<Props>) => {
  const isActive = props.isActive ?? true;
  return (
    <div
      className="l-grid__cell l-grid__cell--12 l-grid__cell--6@small u-flex"
      style={!isActive ? { opacity: 0.6 } : {}}
      onClick={isActive ? props.onClick : () => {}}
    >
      <div
        className={
          "c-box c-box--depth-2 u-flex-auto u-margin-bottom-gutter" +
          (isActive ? " c-box--interactive" : "")
        }
        style={{ position: "relative" }}
      >
        {props.children}
      </div>
    </div>
  );
};
