import { useEffect, useState } from "react";

export interface Config {
  ProovrApiBaseUrl: string;
  ApiBaseUrl: string;
}

export const useConfigLoader = () => {
  const [config, setConfig] = useState<Config | null>(null);

  useEffect(() => {
    (async () => {
      try {
        let result = await fetch("config.json");
        let json = await result.json();
        setConfig(json);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return { config };
};
