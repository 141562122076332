import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Button } from "../../components/Button/Button";
import { LoadingState } from "../../stores/LoadingState";
import { useStores } from "../../hooks/useStores";
import { useHistory } from "react-router-dom";
import { ErrorLabel } from "../../components/ErrorLabel";
import { RoutePath } from "../Router";
import { ColumnPage } from "../../components/ColumnPage";

export const CodeConfirmation = observer(() => {
  const { t } = useTranslation("mobile");
  const { authenticationStore } = useStores();
  const history = useHistory();
  const [error, setError] = useState("");
  const [input, setInput] = useState("");

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await authenticationStore.verifyEmail(input);
      history.push(RoutePath.actions);
    } catch (e: any) {
      console.error(e);
      setError(e.message);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  return (
    <ColumnPage>
      <form data-test-id="codeConfirmationForm" onSubmit={onSubmit}>
        <label
          data-test-id="codeConfirmationInstructionDiv"
          className="u-color-neutral-darker"
          htmlFor="codeConfirmationInput"
        >
          {t("confirmation_code.instruction")}
        </label>
        <div className="u-margin-top-gutter">
          {error !== "" && (
            <ErrorLabel message={error} className={"u-margin-bottom-gutter"} />
          )}
          <input
            data-test-id="codeConfirmationInput"
            value={input}
            onChange={onChange}
            required={true}
            className={"c-input " + (error ? "is-invalid" : "")}
            placeholder={t("confirmation_code.placeholder")}
          />
        </div>
        <div className="u-margin-top-gutter">
          <Button
            isLoading={
              authenticationStore.loadingState === LoadingState.Loading
            }
            testId="codeConfirmationSubmit"
          >
            {t("common.verify")}
          </Button>
        </div>
      </form>
    </ColumnPage>
  );
});
