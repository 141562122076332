import { makeAutoObservable, reaction, runInAction } from "mobx";
import { LoadingState } from "./LoadingState";
import { ProovrApi } from "../services/ProovrApi";
import { Action, ActionScanner, ActionWorkFlow } from "./entities/Action";
import { isMobile } from "react-device-detect";
import { UserLocationStore } from "./UserLocationStore";
import { AnalyticsStore } from "./AnalyticsStore";

export class ActionsStore {
  public loadingState: LoadingState = LoadingState.Loading;
  public actions: Action[] = [];

  constructor(
    private proovrApi: ProovrApi,
    private userLocationStore: UserLocationStore,
    private analyticsStore: AnalyticsStore
  ) {
    makeAutoObservable(this);

    // reacts on changes of locationEnabled to display the proper cell
    reaction(
      () => userLocationStore.locationEnabled,
      (locationEnabled) => {
        (async () => {
          await this.populateActions();
        })();
      }
    );
  }

  public async refresh() {
    await this.userLocationStore.refresh();
    await this.populateActions();
  }

  public async populateActions() {
    const locationEnabled = this.userLocationStore.locationEnabled;
    if (locationEnabled !== null) {
      this.analyticsStore.post({
        type: "actions-page-started",
        "authorized-location": locationEnabled ? "true" : "false",
      });
    }

    const workflows = await this.proovrApi.fetchWorkflows();

    let actions: Action[] = [];
    // scanner
    if (isMobile) {
      actions.push({ actionType: "scanner" } as ActionScanner);
    }

    // geolocation not authorized
    if (locationEnabled !== null && !locationEnabled) {
      actions.push({ actionType: "geo" });
    }

    // workflows
    let actionWorkFlows = workflows.items.map((workflowResponse) => {
      return {
        actionType: "workflow",
        workflowResponse: workflowResponse,
      };
    }) as ActionWorkFlow[];
    actionWorkFlows.forEach((a) => actions.push(a));

    runInAction(() => {
      this.actions = actions;
      this.loadingState = LoadingState.Loaded;
    });
  }

  reset() {
    runInAction(() => {
      this.actions = [];
      this.loadingState = LoadingState.Loading;
    });
  }
}
