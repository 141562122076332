import { makeAutoObservable, runInAction } from "mobx";
import { LoadingState } from "./LoadingState";
import { ProovrApi } from "../services/ProovrApi";
import { VerifyEmailResponse } from "../services/models/VerifyEmailResponse";
import { ApplicationStorage } from "./ApplicationStorage";
import { AnalyticsStore } from "./AnalyticsStore";

export class AuthenticationStore {
  public loadingState: LoadingState = LoadingState.Loaded;
  public email: string = "";
  private authenticationState: string | null = null;
  public isUserAuthenticated: boolean = false;

  constructor(
    private proovrApi: ProovrApi,
    private applicationStorage: ApplicationStorage,
    private analyticsStore: AnalyticsStore
  ) {
    makeAutoObservable(this);
    (async () => {
      await this.refreshIsAuthenticated();
    })();
  }

  public refreshIsAuthenticated() {
    // consider user being authenticated if we have a jwt
    const jwt = this.applicationStorage.get("auth_jwt");
    runInAction(() => {
      this.isUserAuthenticated = !!jwt;
      this.analyticsStore.post({
        type: "user-status",
        authenticated: this.isUserAuthenticated,
      });
    });
  }

  public async requestEmailVerification(email: string): Promise<void> {
    this.authenticationState = this.generateAuthenticationState();
    runInAction(() => {
      this.loadingState = LoadingState.Loading;
    });

    try {
      await this.proovrApi.requestEmailVerification(
        email,
        this.authenticationState
      );
      runInAction(() => {
        this.email = email;
        this.loadingState = LoadingState.Loaded;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingState = LoadingState.Error;
      });
      throw error;
    }
  }

  public async verifyEmail(code: string): Promise<void> {
    runInAction(() => {
      this.loadingState = LoadingState.Loading;
    });
    try {
      let emailResponse: VerifyEmailResponse = await this.proovrApi.verifyEmail(
        this.email,
        this.authenticationState,
        code
      );
      this.applicationStorage.set(emailResponse.emailCredential, "auth_jwt");
      await this.refreshIsAuthenticated();
      this.analyticsStore.authenticationSucceeded();
      runInAction(() => {
        this.loadingState = LoadingState.Loaded;
      });
    } catch (error) {
      runInAction(() => {
        this.isUserAuthenticated = false;
        this.loadingState = LoadingState.Error;
      });
      throw error;
    }
  }

  private generateAuthenticationState(): string {
    const uuid = require("uuid");
    return uuid.v4();
  }

  public logout() {
    this.applicationStorage.clear();
    this.refreshIsAuthenticated();
  }
}
