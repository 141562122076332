import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useStores } from "../../hooks/useStores";
import { CenteredSpinner } from "../../components/Spinner/Spinner";
import { LoadingState } from "../../stores/LoadingState";
import { GeolocationCard } from "./geolocation/GeolocationCard";
import {
  ActionWorkFlow,
  isActionGeolocation,
  isActionScanner,
  isActionWorkFlow,
} from "../../stores/entities/Action";
import { WorkflowCardDefault } from "./workflows/WorkflowCardDefault";
import { GeolocalizedWorkflowCard } from "./workflows/GeolocalizedWorkflowCard";
import { ScannerCard } from "./scanner/ScannerCard";
import { Page } from "../../components/Page";
import { Icon } from "../../assets/img/Icon";
import { RoutePath } from "../Router";
import { useHistory } from "react-router-dom";

export const Actions = observer(() => {
  const history = useHistory();
  const rootStore = useStores();
  const { actionsStore } = rootStore;

  useEffect(() => {
    (async () => {
      await actionsStore.refresh();
    })();
  }, []);

  const { applicationStorage } = useStores();

  useEffect(() => {
    const workflowURL = applicationStorage.get(
      "workflowURLNavigationParameter"
    );
    if (workflowURL) {
      history.push(RoutePath.qrCodeScanner, { workflowURL: workflowURL });
      applicationStorage.remove("workflowURLNavigationParameter");
    }
  }, []);

  const ActionsCards = observer(() => {
    return (
      <>
        {actionsStore.actions.map((action) => {
          if (isActionScanner(action)) {
            return (
              <ScannerCard
                key={action.actionType}
                data-test-id={action.actionType}
              />
            );
          } else if (isActionGeolocation(action)) {
            const actionGeolocation: ActionWorkFlow = action;
            return (
              <GeolocationCard
                key={actionGeolocation.actionType}
                data-test-id={actionGeolocation.actionType}
                onClick={() => actionsStore.refresh()}
              />
            );
          }
          if (isActionWorkFlow(action)) {
            const actionWorkFlow: ActionWorkFlow = action;
            if (actionWorkFlow.workflowResponse.geoLocationCondition === null) {
              return (
                <WorkflowCardDefault
                  key={actionWorkFlow.workflowResponse.workflowId}
                  data-test-id={actionWorkFlow.workflowResponse.workflowId}
                  workflowResponse={actionWorkFlow.workflowResponse}
                />
              );
            } else {
              return (
                <GeolocalizedWorkflowCard
                  key={actionWorkFlow.workflowResponse.workflowId}
                  data-test-id={actionWorkFlow.workflowResponse.workflowId}
                  workflowResponse={actionWorkFlow.workflowResponse}
                />
              );
            }
          } else {
            return <div />;
          }
        })}
      </>
    );
  });

  return (
    <Page>
      <Header />
      <main className={"u-flex u-flex-auto"}>
        {actionsStore.loadingState === LoadingState.Loading && (
          <div className={"u-flex u-flex-grow1"}>
            <CenteredSpinner />
          </div>
        )}
        {actionsStore.loadingState === LoadingState.Loaded && (
          <div className="l-container u-padding-h-outer u-margin-v-outer u-margin-v-xl@medium u-max-width-700@medium">
            <div className="l-grid l-grid--stretch">
              <ActionsCards />
            </div>
          </div>
        )}
      </main>
    </Page>
  );
});

const Header = () => {
  const { t } = useTranslation("mobile");
  const rootStore = useStores();

  return (
    <header
      className="l-container u-padding-h-outer u-color-bg-white u-border-bottom"
      style={{ position: "sticky", top: 0, zIndex: 500 }}
    >
      <div className="u-flex u-flex-align-items-center u-padding-v-gutter">
        <div className="u-flex u-flex-nowrap u-flex-align-items-center u-text-ellipsis">
          <Icon width={40} />
          <h1 className="c-heading c-heading--m u-margin-left-xs u-text-ellipsis">
            {t("onboarding.welcome")}
          </h1>
        </div>
        <div className="u-margin-left-auto">
          <a
            className="c-link c-link--primary c-link--no-underline u-text-weight-medium u-flex u-flex-align-items-center u-flex-nowrap"
            onClick={() => {
              if (window.confirm(t("settings.log_out_question"))) {
                rootStore.logout();
              }
            }}
          >
            <svg className="icon-arrow-right-24">
              <use href="#arrow-right-24"></use>
            </svg>
            <span className="u-text-nowrap">{t("settings.log_out")}</span>
          </a>
        </div>
      </div>
    </header>
  );
};
