import React from "react";
export const GeolocationCardBackground = () => {
  return (
    <svg
      width="355"
      height="161"
      viewBox="0 0 355 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: -15 }}
    >
      <g opacity="0.4">
        <path
          opacity="0.1"
          d="M79.6068 80.7094C79.6068 123.893 45.6142 158.801 3.80338 158.801C-38.0074 158.801 -72 123.893 -72 80.7094C-72 37.5255 -38.0074 2.61768 3.80338 2.61768C45.6142 2.61768 79.6068 37.5255 79.6068 80.7094Z"
          stroke="#FF8839"
          strokeWidth="4"
        />
        <path
          opacity="0.3"
          d="M61.2565 80.7427C61.2565 113.494 35.4795 139.945 3.80301 139.945C-27.8734 139.945 -53.6505 113.494 -53.6505 80.7427C-53.6505 47.9912 -27.8734 21.5405 3.80301 21.5405C35.4795 21.5405 61.2565 47.9912 61.2565 80.7427Z"
          stroke="#FF8839"
          strokeWidth="4"
        />
        <path
          opacity="0.5"
          d="M42.8516 80.7088C42.8516 103.089 25.2422 121.131 3.64109 121.131C-17.96 121.131 -35.5695 103.089 -35.5695 80.7088C-35.5695 58.3289 -17.96 40.2861 3.64109 40.2861C25.2422 40.2861 42.8516 58.3289 42.8516 80.7088Z"
          stroke="#FF8839"
          strokeWidth="4"
        />
        <path
          d="M26.3002 80.709C26.3002 93.7171 16.071 104.163 3.57387 104.163C-8.92329 104.163 -19.1525 93.7171 -19.1525 80.709C-19.1525 67.7008 -8.92329 57.2554 3.57387 57.2554C16.071 57.2554 26.3002 67.7008 26.3002 80.709Z"
          stroke="#FF8839"
          strokeWidth="4"
        />
      </g>
    </svg>
  );
};
