import React, { useEffect } from "react";
import { WorkflowResponse } from "../../../services/models/WorkflowResponse";
import { useWorkflowDetail } from "./useWorkflowDetail";
import { CenteredSpinner } from "../../../components/Spinner/Spinner";
import { WorkflowResult } from "./WorkflowResult";
import { ModalContainer } from "../../../components/ModalContainer";

export interface Props {
  workflow: WorkflowResponse | null;
  onClose(): void;
}

export const WorkflowDetail = (props: Props) => {
  const { result, redirect, isLoading, startWorkflow } = useWorkflowDetail();

  const onMessage = (event: MessageEvent) => {
    const dataURL = event.data?.data?.url;
    if (dataURL) {
      const url = new URL(dataURL, document.location.toString());
      const resultValue = url.searchParams.get("ProovrResult");
      if (resultValue && resultValue === "OK") {
        close();
      }
    }
  };

  useEffect(() => {
    // listen to onMessage calls from the workflows we visit
    window.addEventListener("message", onMessage, false);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  useEffect(() => {
    const workflow = props.workflow;
    if (workflow !== null) {
      (async () => {
        await startWorkflow(workflow);
      })();
    }
  }, [props.workflow]);

  const close = () => {
    props.onClose();
  };

  return (
    <ModalContainer onClose={close}>
      {isLoading && <CenteredSpinner />}
      {result.showResult && (
        <WorkflowResult
          isSucceeded={result.isSucceeded}
          resultMessage={result.message}
          onTap={close}
        />
      )}
      {redirect.shouldRedirect && !result.showResult && (
        // for the iframe to expand to 100% height all its parents must be 100% height
        <div style={{ height: "100%" }}>
          <iframe
            title={"proovr web"}
            width={"100%"}
            height={"100%"}
            frameBorder={0}
            src={redirect.url}
            onLoad={() => {}}
          />
        </div>
      )}
    </ModalContainer>
  );
};
