import * as axios from "axios";
import { DateTime } from "luxon";
import { isMobile } from "react-device-detect";
import { ApplicationStorage } from "./ApplicationStorage";
import Cookies from "js-cookie";

type bodyAuthorization = "undefined" | "true" | "false";
interface AnalyticsBody {
  type: string;
  url?: string;
  "page-name"?: string;
  "url-parameters"?: string;
  duration?: number;
  "http-response-status"?: number;
  "http-response-message"?: number;
  "http-response-success"?: boolean;
  "authorized-location"?: bodyAuthorization;
  "authorized-camera"?: bodyAuthorization;
  authenticated?: boolean;
}

export class AnalyticsStore {
  private readonly analyticsUserId: string;

  private client: axios.AxiosInstance = axios.default.create({
    baseURL: this.baseURL,
  });

  constructor(
    private applicationStorage: ApplicationStorage,
    private baseURL: string
  ) {
    const uuid = require("uuid");
    this.analyticsUserId =
      applicationStorage.get("analyticsUserId") ?? uuid.v4();
    applicationStorage.set(this.analyticsUserId, "analyticsUserId");
    if (!Cookies.get("session")) {
      Cookies.set("session", uuid.v4());
    }
  }

  private authenticationTimer: DateTime | null = null;
  public authenticationStarted() {
    this.authenticationTimer = DateTime.local();
    this.post({ type: "authentication-started" });
  }

  public authenticationSucceeded() {
    const secondsPassed = DateTime.local().diff(
      this.authenticationTimer ?? DateTime.local(),
      "seconds"
    ).seconds;
    this.post({
      type: "authentication-succeeded",
      duration: secondsPassed,
    });
  }

  public post(body: AnalyticsBody) {
    const agentType = isMobile ? "mobile" : "desktop";
    const httpBody = {
      source: "proovr-web",
      "agent-type": agentType,
      "session-id": Cookies.get("session"),
      "user-id": this.analyticsUserId,
      ...body,
    };
    //console.log("### Analytics.post", body);
    (async () => {
      try {
        await this.client.post("/stats", httpBody);
      } catch (e) {
        console.log(e);
      }
    })();
  }
}
