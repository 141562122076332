import { makeAutoObservable, runInAction } from "mobx";

export class UserLocationStore {
  public userLocation: Position | null = null;
  public locationEnabled: boolean | null = null;
  private geolocationHandlerId: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  private registerGeolocationHandler = () => {
    return navigator.geolocation.watchPosition(
      (location) => {
        runInAction(() => {
          this.userLocation = location;
          this.locationEnabled = true;
        });
      },
      () => {
        runInAction(() => {
          this.userLocation = null;
          this.locationEnabled = false;
        });
        console.log(
          "Retrieving user location failed. Most likely location access permission is denied."
        );
      }
    );
  };

  public refresh() {
    if (this.geolocationHandlerId !== 0) {
      navigator.geolocation.clearWatch(this.geolocationHandlerId);
    }
    this.geolocationHandlerId = this.registerGeolocationHandler();
  }
}
