import React from "react";

interface Props {}
export const Page = (props: React.PropsWithChildren<Props>) => {
  return (
    <div
      className={
        "u-color-bg-neutral-off is-proovr u-flex u-flex-justify-stretch u-flex-col"
      }
      style={{ minHeight: "100vh" }}
    >
      {props.children}
    </div>
  );
};
