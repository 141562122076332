import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import SuccessIcon from "@proxyclick/hello-icons/dist/svg/checkmark-circle--color-32.svg";
import FailureIcon from "@proxyclick/hello-icons/dist/svg/danger--color-24.svg";
import { Button } from "../../../components/Button/Button";
import { Title } from "../../../components/Title";

interface Props {
  isSucceeded: boolean;
  resultMessage: string | null;
  onTap(): void;
}

export const WorkflowResult = (props: Props) => {
  const { isSucceeded, resultMessage, onTap } = props;
  const { t } = useTranslation("mobile");

  const buttonTitle = isSucceeded ? t("common.done") : t("common.cancel");
  const title = isSucceeded
    ? t("workflow.result_title_succeeded")
    : t("workflow.result_title_failed");
  return (
    <div
      className={
        "u-flex u-flex-auto u-flex-col u-color-bg-neutral-off u-padding-all-l"
      }
    >
      <Title title={title} className={"u-margin-bottom-none"} />
      <div
        className={
          "u-flex u-flex-auto u-flex-grow1 u-flex-align-items-center u-flex-justify-center"
        }
      >
        <div className={"u-align-center"}>
          <img
            src={isSucceeded ? SuccessIcon : FailureIcon}
            style={{ width: 80 }}
          />
          <div className={"u-color-neutral-medium u-text-l"}>
            {resultMessage}
          </div>
        </div>
      </div>
      <Button onClick={onTap}>{buttonTitle}</Button>
    </div>
  );
};
