import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { WorkflowResponse } from "../../../services/models/WorkflowResponse";
import { ActionsCard } from "../ActionsCard";
import { WorkflowDetail } from "./WorkflowDetail";
import { useStores } from "../../../hooks/useStores";

interface Props {
  workflowResponse: WorkflowResponse;
  name: string;
  description: string;
  isActive: boolean;
}

export const WorkflowCard = observer((props: Props) => {
  const { applicationStorage, actionsStore } = useStores();
  const [workflowDetail, setWorkflowDetail] = useState<WorkflowResponse | null>(
    null
  );

  useEffect(() => {
    const workflow = applicationStorage.get("workflowNavigationParameter");
    if (workflow && workflow === props.workflowResponse.workflowId) {
      setWorkflowDetail(props.workflowResponse);
      applicationStorage.remove("workflowNavigationParameter");
    }
  }, []);

  return (
    <>
      {workflowDetail !== null && (
        <WorkflowDetail
          workflow={workflowDetail}
          onClose={async () => {
            setWorkflowDetail(null);
            await actionsStore.refresh();
          }}
        />
      )}
      <ActionsCard
        isActive={props.isActive}
        onClick={() => {
          setWorkflowDetail(props.workflowResponse);
        }}
      >
        <div className="u-width-50 u-margin-bottom-xs">
          <div className="c-image-fit c-image-fit--square c-image-fit--cover">
            <img
              alt="logo"
              src={props.workflowResponse.logoUrl}
              style={{ borderRadius: "25px" }}
            />
          </div>
        </div>
        <div className="c-heading c-heading--xl u-margin-bottom-none u-margin-bottom-2xs@medium">
          {props.name}
        </div>
        <div className="u-text-s u-text-m@medium u-color-neutral-dark">
          {props.description}
        </div>
      </ActionsCard>
    </>
  );
});
