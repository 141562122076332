// import * as ApplicationInfo from "../common/ApplicationInfo";
import * as axios from "axios";
import { AxiosResponse } from "axios";
import {
  ConfirmationCodeError,
  EmailError,
  InvalidWorkflowError,
  NetworkError,
} from "../common/errors/ApiErrors";
import { PaginatedResponse } from "./models/PaginatedResponse";
import { VerifyEmailResponse } from "./models/VerifyEmailResponse";
import {
  isWorkflowResponse,
  WorkflowResponse,
} from "./models/WorkflowResponse";
import { StartWorkflowResponse } from "./models/StartWorkflowResponse";
import { ApplicationStorage } from "../stores/ApplicationStorage";
import { userLanguageCode } from "../common/ApplicationInfo";
import { AnalyticsStore } from "../stores/AnalyticsStore";

//used to use the buffer to download image as base 64 with axios.
global.Buffer = global.Buffer || require("buffer").Buffer;
export class ProovrApi {
  private client: axios.AxiosInstance = axios.default.create({
    baseURL: this.baseUrl,
    headers: this.headers,
  });

  public unauthorizedErrorHandler: (() => void) | null = null;
  constructor(
    private baseUrl: string,
    private applicationStorage: ApplicationStorage,
    private analyticsStore: AnalyticsStore
  ) {
    this.client.interceptors.response.use(
      (res) => {
        return res;
      },

      (error) => {
        if (error.response?.status === 401) {
          // Analytics.reportError("API UnauthorizedError", error.request.url);
          if (this.unauthorizedErrorHandler) {
            this.unauthorizedErrorHandler();
          }
        } else if (error.message === "Network Error") {
          return Promise.reject(new NetworkError());
        }
        return Promise.reject(error);
      }
    );
  }

  private get headers(): any {
    return {
      "Content-Type": "application/json",
      "Accept-Language": userLanguageCode(),
      // that doesn't work, so we set a custom header "application-agent"
      // "user-agent": "Proovr",
      "application-agent": "Proovr",
    };
  }

  private logHttpSuccess(response: AxiosResponse, type: string) {
    this.analyticsStore.post({
      type: type,
      "http-response-status": response.status,
      "http-response-success": true,
    });
  }

  private logHttpError(error: any, type: string) {
    this.analyticsStore.post({
      type: type,
      "http-response-status": error.response?.status,
      "http-response-message": error.response?.statusText,
      "http-response-success": false,
    });
  }

  public get authenticatedHeaders(): any {
    const jwt = this.applicationStorage.get("auth_jwt");
    if (jwt === null) {
      throw "null JWT";
    }
    return {
      Authorization: `Bearer ${jwt}`,
    };
  }

  public async requestEmailVerification(
    email: string,
    authenticationState: string
  ): Promise<void> {
    const url: string = `${this.baseUrl}/v0.1/credentials/email/request`;
    try {
      const response = await this.client.post<AxiosResponse>(url, {
        Email: email,
        State: authenticationState,
      });
      this.logHttpSuccess(response, "authentication-email-http-request");
    } catch (error) {
      this.logHttpError(error, "authentication-code-http-request");
      const emailErrors = error?.response?.data?.errors["Email"];
      if (emailErrors && emailErrors[0]) {
        throw new EmailError(emailErrors[0].split("|")[0]);
      }
      throw error;
    }
  }

  public async verifyEmail(
    email: string | null,
    authenticationState: string | null,
    code: string
  ): Promise<VerifyEmailResponse> {
    const url: string = `${this.baseUrl}/v0.1/credentials/email/verify`;
    try {
      const response = await this.client.post<VerifyEmailResponse>(url, {
        Email: email,
        State: authenticationState,
        Token: code,
      });
      this.logHttpSuccess(response, "authentication-code-http-request");
      return response.data;
    } catch (error) {
      this.logHttpError(error, "authentication-code-http-request");
      const codeError = error?.response?.data?.errors["Token"];
      if (codeError && codeError[0]) {
        throw new ConfirmationCodeError(codeError[0].split("|")[0]);
      }
      throw error;
    }
  }

  public async fetchWorkflows(): Promise<PaginatedResponse<WorkflowResponse>> {
    const url: string = `${this.baseUrl}/v0.1/identities/me/workflows`;
    const response = await this.client.get<PaginatedResponse<WorkflowResponse>>(
      url,
      {
        params: { page: 1, pageSize: 100 },
        headers: this.authenticatedHeaders,
      }
    );
    return response.data;
  }

  public async getWorkflowFromUrl(url: string): Promise<WorkflowResponse> {
    let response: AxiosResponse<WorkflowResponse> = await this.client.get(url);
    const isResponseValid = isWorkflowResponse(response.data);
    if (!isResponseValid) {
      throw new InvalidWorkflowError();
    } else {
      return response.data;
    }
  }

  public async startWorkflow(
    startUrl: string,
    requestedCredentials: Map<string, string>
  ): Promise<StartWorkflowResponse> {
    try {
      const jsonCredentials = fromMapToObj<string, string>(
        requestedCredentials
      );
      const response = await this.client.post<StartWorkflowResponse>(startUrl, {
        Credentials: jsonCredentials,
      });
      this.logHttpSuccess(response, "start-workflow-http-request");
      return response.data;
    } catch (error) {
      this.logHttpError(error, "start-workflow-http-request");
      throw error;
    }
  }

  public async unRegisterDevice(installationId: string): Promise<any> {
    const url: string = `${this.baseUrl}/v0.1/identities/me/devices/${installationId}`;
    try {
      return await this.client.delete(url, {
        headers: this.authenticatedHeaders,
      });
    } catch (error) {
      // Analytics.reportError("unRegisterDevice", error.message);
      if (error.message === "Network Error") {
        throw new NetworkError();
      } else {
        throw new Error(error.message);
      }
    }
  }

  public async translationReportMissingKey(
    language: string,
    key: string
  ): Promise<any> {
    const url: string = `${this.baseUrl}/v1/localization/errors/keys`;
    try {
      return await this.client.post(url, { language: language, key: key });
    } catch (error) {
      // Analytics.reportError("translationReportMissingKey", error.message);
      if (error.message === "Network Error") {
        throw new NetworkError();
      } else {
        throw new Error(error.message);
      }
    }
  }

  public async translationReportMissingValue(
    language: string,
    value: string,
    match: string
  ): Promise<any> {
    const url: string = `${this.baseUrl}/v1/localization/errors/values`;
    try {
      return await this.client.post(url, {
        language: language,
        source: value,
        match: match,
      });
    } catch (error) {
      // Analytics.reportError("translationReportMissingValue", error.message);
      if (error.message === "Network Error") {
        throw new NetworkError();
      } else {
        throw new Error(error.message);
      }
    }
  }
}

export function fromMapToObj<K, V>(map: Map<K, V>): any {
  const obj = Object.create(null);
  map.forEach((value, key) => {
    obj[key] = value;
  });

  return obj;
}
