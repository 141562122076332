import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Email } from "./startup/Email";
import { CodeConfirmation } from "./startup/CodeConfirmation";
import { Actions } from "./actions/Actions";
import { useStores } from "../hooks/useStores";
import { observer } from "mobx-react-lite";
import { ScannerDetail } from "./actions/scanner/ScannerDetail";

export class RoutePath {
  static email = "/email";
  static codeConfirmation = "/code_confirmation";
  static actions = "/actions";
  static qrCodeScanner = "/qrcode_scanner";
  static isAuthenticatedPath(path: string): boolean {
    return [RoutePath.actions].indexOf(path) >= 0;
  }
  static isUnAuthenticatedPath(path: string): boolean {
    return [RoutePath.email, RoutePath.codeConfirmation].indexOf(path) >= 0;
  }
}

export const Router = observer(() => {
  let history = useHistory();
  const {
    authenticationStore,
    applicationStorage,
    analyticsStore,
  } = useStores();

  const pathName = window.location.pathname;
  const urlParameters = new URLSearchParams(window.location.search);

  if (pathName === "/") {
    const workflowParameter = urlParameters.get("workflow");
    if (workflowParameter) {
      applicationStorage.set(workflowParameter, "workflowNavigationParameter");
    }
    const workflowURLParameter = urlParameters.get("workflowURL");
    if (workflowURLParameter) {
      applicationStorage.set(
        workflowURLParameter,
        "workflowURLNavigationParameter"
      );
    }
  }

  if (authenticationStore.isUserAuthenticated) {
    // if user tries to visit an unauthenticated route, bring him back to default authenticated route
    if (!RoutePath.isAuthenticatedPath(pathName)) {
      history.replace(RoutePath.actions);
    }
  } else if (
    pathName === RoutePath.codeConfirmation &&
    authenticationStore.email === ""
  ) {
    //to go back to email if the code confirmation is refreshed and no email is saved in authenticaiton store
    history.replace(RoutePath.email);
  } else if (!RoutePath.isUnAuthenticatedPath(pathName)) {
    // prevent user from accessing authenticated routes
    history.replace(RoutePath.email);
  }

  let analyticsURLParameters = "";
  urlParameters.forEach((value, parameter) => {
    analyticsURLParameters += parameter + ",";
  });
  analyticsURLParameters = analyticsURLParameters.slice(0, -1);
  analyticsStore.post({
    type: "pageview",
    url: pathName,
    "page-name": pathName.replace("/", ""),
    "url-parameters": analyticsURLParameters,
  });

  return (
    <>
      <Switch>
        <Route path={RoutePath.email} component={Email} />
        <Route path={RoutePath.codeConfirmation} component={CodeConfirmation} />
        <Route path={RoutePath.actions} component={Actions} />
        <Route path={RoutePath.qrCodeScanner} component={ScannerDetail} />
      </Switch>
    </>
  );
});
