import i18n from "i18next";

export class EmailError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class ConfirmationCodeError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class NetworkError extends Error {
  constructor() {
    super(i18n.t("common.no_internet_connection"));
  }
}

export class InvalidWorkflowError extends Error {
  constructor() {
    super(i18n.t("workflow.invalid_workflow_exception"));
  }
}
