import React from "react";
import { observer } from "mobx-react-lite";
import { ActionsCard } from "../ActionsCard";
import { useHistory } from "react-router-dom";
import { RoutePath } from "../../Router";
import { ScanQRCodeIcon } from "../../../assets/img/ScanQRCodeIcon";
import { useTranslation } from "react-i18next";

export const ScannerCard = observer(() => {
  const history = useHistory();
  const { t } = useTranslation("mobile");

  return (
    <>
      <ActionsCard
        onClick={() => {
          history.push(RoutePath.qrCodeScanner);
        }}
      >
        <div className="u-width-50 u-margin-bottom-xs">
          <ScanQRCodeIcon />
        </div>
        <div className="c-heading c-heading--xl u-line-height-6u u-margin-bottom-2xs u-margin-bottom-2xs@medium">
          {t("actions.card_scan_title")}
        </div>
        <div className="u-text-s u-text-m@medium u-color-neutral-dark">
          {t("actions.card_scan_description")}
        </div>
      </ActionsCard>
    </>
  );
});
