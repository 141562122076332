import React from "react";

export const ScanQRCodeIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#F3F3F6" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15 24H24V15H15V24ZM22 22H17V17H22V22ZM26 35H29V33H28V31H26V35ZM26 29V26H30V28H28V29H26ZM35 32V35H31V33H33V32H35ZM35 26H32V28H33V30H35V26ZM29 29H32V32H29V29ZM31 30H30V31H31V30ZM21 18H18V21H21V18ZM26 24H35V15H26V24ZM33 22H28V17H33V22ZM32 18H29V21H32V18ZM15 35H24V26H15V35ZM22 33H17V28H22V33ZM21 29H18V32H21V29Z"
        fill="#39396A"
      />
    </svg>
  );
};
