import React from "react";

interface Props {
  title: string;
  className?: string;
}

export const Title = (props: Props) => {
  return (
    <div
      className={
        "c-heading c-heading--xl u-align-center u-margin-bottom-m" +
          props.className ?? ""
      }
    >
      {props.title}
    </div>
  );
};
