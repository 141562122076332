export function userLanguageCode(): string {
  return window.navigator.language;
}

export function userLanguageCodeShort(): string {
  return userLanguageCode().substring(0, 2);
}

export function countryCode(): string {
  return userLanguageCodeShort().toLowerCase() === "en" ? "US" : "BE";
}
