import { observer } from "mobx-react-lite";
import React, { MouseEventHandler } from "react";
import { ActionsCard } from "../ActionsCard";
import { useTranslation } from "react-i18next";
import { GeolocationCardBackground } from "../../../assets/img/geolocationCardBackground";

interface Props {
  onClick: MouseEventHandler;
}

export const GeolocationCard = observer((props: Props) => {
  const { t } = useTranslation("mobile");

  return (
    <>
      <ActionsCard onClick={props.onClick}>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
            borderRadius: 15,
          }}
        >
          <GeolocationCardBackground />
        </div>
        <div className="c-heading c-heading--xl u-margin-bottom-2xs@medium">
          {t("permissions.geolocalization_not_authorized_description")}
        </div>
        <div className="u-text-s u-text-m@medium u-color-neutral-dark u-margin-bottom-2xs@medium">
          {t("permissions.geolocalization_not_authorized_subtitle")}
        </div>
      </ActionsCard>
    </>
  );
});
