import { ProovrApi } from "../services/ProovrApi";
import { AuthenticationStore } from "./AuthenticationStore";
import { ApplicationStorage } from "./ApplicationStorage";
import { ActionsStore } from "./ActionsStore";
import { Config } from "../hooks/useConfigLoader";
import { UserLocationStore } from "./UserLocationStore";
import { AnalyticsStore } from "./AnalyticsStore";

export class RootStore {
  public applicationStorage: ApplicationStorage = new ApplicationStorage();
  public proovrApi: ProovrApi;
  public authenticationStore: AuthenticationStore;
  public userLocationStore: UserLocationStore;
  public actionsStore: ActionsStore;
  public analyticsStore: AnalyticsStore;

  constructor(config: Config) {
    this.analyticsStore = new AnalyticsStore(
      this.applicationStorage,
      config.ApiBaseUrl
    );
    this.proovrApi = new ProovrApi(
      config.ProovrApiBaseUrl,
      this.applicationStorage,
      this.analyticsStore
    );
    this.authenticationStore = new AuthenticationStore(
      this.proovrApi,
      this.applicationStorage,
      this.analyticsStore
    );
    this.userLocationStore = new UserLocationStore();
    this.actionsStore = new ActionsStore(
      this.proovrApi,
      this.userLocationStore,
      this.analyticsStore
    );
  }

  public logout() {
    this.analyticsStore.post({ type: "log-out" });
    this.actionsStore.reset();
    this.authenticationStore.logout();
  }
}
