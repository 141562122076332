import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NetworkError } from "../../../common/errors/ApiErrors";
import { useStores } from "../../../hooks/useStores";
import { WorkflowResponse } from "../../../services/models/WorkflowResponse";

export interface WorkflowRedirectState {
  shouldRedirect: boolean;
  url: string;
  webViewNavigationHandler: (url: URL) => void;
}

export interface WorkflowResultState {
  showResult: boolean;
  isSucceeded: boolean;
  message: string | null;
}

export const useWorkflowDetail = () => {
  const { proovrApi, applicationStorage } = useStores();
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation("mobile");

  const initialWorkflowResultState = {
    showResult: false,
    isSucceeded: false,
    message: "",
  };
  const [result, setResult] = useState<WorkflowResultState>(
    initialWorkflowResultState
  );
  const [redirect, setRedirect] = useState<WorkflowRedirectState>({
    shouldRedirect: false,
    url: "",
    webViewNavigationHandler: handleWebViewNavigation,
  });

  async function startWorkflow(workflow: WorkflowResponse): Promise<void> {
    setResult(initialWorkflowResultState);
    setIsLoading(true);
    try {
      const credentials = new Map<string, string>();
      const jwt = applicationStorage.get("auth_jwt");
      if (workflow.requestedCredentials && jwt) {
        for (const [key] of Object.entries(workflow.requestedCredentials)) {
          if (key === "Email" && jwt) {
            credentials.set(key, jwt);
          }
        }
      }

      const response = await proovrApi.startWorkflow(
        workflow.startUrl,
        credentials
      );

      if (response?.status === "REDIRECT" || response?.status === 2) {
        redirectToUrl(response.redirectIdentityToUrl);
      } else {
        setResult({
          isSucceeded: response.status === "OK",
          showResult: true,
          message: response.message,
        });
      }
    } catch (error) {
      if (error instanceof NetworkError) {
        setFailedResult(error.message);
      } else {
        setFailedResult(t("common.error_generic"));
      }
    } finally {
      setIsLoading(false);
    }
  }

  function redirectToUrl(url: string | undefined): void {
    if (url) {
      setRedirect({
        shouldRedirect: true,
        url: url,
        webViewNavigationHandler: handleWebViewNavigation,
      });
    } else {
      setFailedResult(t("workflow.could_not_redirect"));
    }
  }

  function setFailedResult(message: string): void {
    setResult({
      showResult: true,
      isSucceeded: false,
      message: message,
    });
  }

  function handleWebViewNavigation(url: URL) {
    const resultParam = url.searchParams.get("ProovrResult");
    const messageParam = url.searchParams.get("ProovrMessage");
    if (resultParam && messageParam) {
      setResult({
        showResult: true,
        isSucceeded: resultParam === "OK",
        message: messageParam,
      });
      setRedirect((red) => {
        red.shouldRedirect = false;
        return red;
      });
    } else if (resultParam && !messageParam) {
      setResult((res) => {
        res.showResult = false;
        return res;
      });
    }
  }

  return {
    result,
    redirect,
    isLoading,
    startWorkflow,
  };
};
