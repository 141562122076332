import { WorkflowResponse } from "../../../services/models/WorkflowResponse";
import { useTranslation } from "react-i18next";
import { useGeolocalizedWorkflow } from "./useGeolocalizedWorkflow";

export const useGeolocalizedWorkflowCard = (workflow: WorkflowResponse) => {
  const { t } = useTranslation("mobile");

  const translationBasePath = `workflows:${
    workflow.localizationKey ?? ""
  }.action.${workflow.enabled ? "enabled" : "disabled"}`;
  let translationInterpolations = workflow.variables;
  const name = t(translationBasePath + ".title", translationInterpolations);

  const {
    isGeolocationAuthorized,
    userWasPreviouslyInRange,
    userToLocationRadiusDistance,
  } = useGeolocalizedWorkflow({
    workflow: workflow,
  });

  let isActive = true;

  let workflowGeolocalizationPath = `workflows:${
    workflow.localizationKey ?? ""
  }.action.geolocalization.`;
  let distanceDescriptionTranslationKey = "";
  if (userWasPreviouslyInRange) {
    distanceDescriptionTranslationKey =
      workflowGeolocalizationPath + "inRange.subtitle";
  } else if (!isGeolocationAuthorized) {
    isActive = false;
    distanceDescriptionTranslationKey =
      "workflow.geolocalization_not_authorized_description";
  } else if (
    userToLocationRadiusDistance !== null &&
    userToLocationRadiusDistance !== undefined
  ) {
    const locationExtraRadius = 1000;
    if (userToLocationRadiusDistance <= 0) {
      distanceDescriptionTranslationKey =
        workflowGeolocalizationPath + "inRange.subtitle";
    } else if (userToLocationRadiusDistance < locationExtraRadius) {
      isActive = false;
      translationInterpolations.distance = userToLocationRadiusDistance.toString();
      distanceDescriptionTranslationKey =
        workflowGeolocalizationPath + "inLongRange.subtitle";
    } else {
      isActive = false;
      distanceDescriptionTranslationKey =
        workflowGeolocalizationPath + "outOfRange.subtitle";
    }
  }

  return {
    name,
    description: t(
      distanceDescriptionTranslationKey,
      translationInterpolations
    ),
    isActive,
  };
};
