import React, { CSSProperties, useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import { useTranslation } from "react-i18next";
import { SpinnerWithBorder } from "./Spinner/Spinner";

interface Props {
  isLoading?: boolean;
  showViewFinder?: boolean;
  setQRCodeData(data: string | null): void;
  onQrCodeOnError(value: string): void;
}

const styles: { [key: string]: CSSProperties } = {
  relative: {
    position: "relative",
  },
  viewFinder: {
    top: 0,
    left: 0,
    zIndex: 1,
    boxSizing: "border-box",
    border: "50px solid rgba(0, 0, 0, 0.4)",
    boxShadow: "inset 0 0 0 2px rgba(255, 255, 255, 1)",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
};

export const QRScanner = (props: Props) => {
  const [qrCodeOnError, setQrCodeOnError] = useState<string | null>(null);
  const { t } = useTranslation("mobile");

  useEffect(() => {
    if (qrCodeOnError) {
      props.onQrCodeOnError(qrCodeOnError);
    }
  }, [qrCodeOnError]);

  const Error = () => {
    return (
      <>
        <div className={"u-padding-all-m u-align-center u-text-xl"}>
          {t("permissions.camera_not_authorized_title")}
        </div>
      </>
    );
  };

  return (
    <div>
      <div style={styles.relative}>
        {(props.isLoading ?? false) && <SpinnerWithBorder />}
        {(props.showViewFinder ?? true) && (
          <div
            style={styles.viewFinder}
            className={
              "u-flex u-flex-col u-flex-align-items-center u-flex-justify-center"
            }
          >
            {qrCodeOnError !== null && <Error />}
          </div>
        )}
        <QrReader
          style={styles.relative}
          delay={300}
          onError={(error) => {
            const domError = error as DOMException;
            if (domError) {
              setQrCodeOnError(domError.name);
            } else {
              setQrCodeOnError("error");
            }
          }}
          onScan={(data: string | null) => props.setQRCodeData(data)}
          showViewFinder={false}
        />
      </div>
    </div>
  );
};
