import { observer } from "mobx-react-lite";
import React from "react";
import { WorkflowResponse } from "../../../services/models/WorkflowResponse";
import { useGeolocalizedWorkflowCard } from "./useGeolocalizedWorkflowCard";
import { WorkflowCard } from "./WorkflowCard";

interface Props {
  workflowResponse: WorkflowResponse;
}

export const GeolocalizedWorkflowCard = observer((props: Props) => {
  const { name, description, isActive } = useGeolocalizedWorkflowCard(
    props.workflowResponse
  );
  return (
    <WorkflowCard
      name={name}
      description={description}
      isActive={isActive}
      workflowResponse={props.workflowResponse}
    />
  );
});
