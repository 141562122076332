import getDistance from "geolib/es/getDistance";
import { DateTime } from "luxon";
import { WorkflowResponse } from "../../../services/models/WorkflowResponse";
import { useStores } from "../../../hooks/useStores";

export interface WorkflowGeolocationState {
  userWasPreviouslyInRange: boolean;
  userToLocationRadiusDistance: number | null;
}

const TIMERS_KEY = "@WorkflowTimerKey";
const TIMER_ACTIVE_IN_SECONDS = 300;

function saveTimerWithId(id: string) {
  let timersJSON = localStorage.getItem(TIMERS_KEY);
  let timers: Map<string, string>;
  if (timersJSON) {
    timers = new Map(JSON.parse(timersJSON));
  } else {
    timers = new Map<string, string>();
  }
  timers.set(id, DateTime.local().toISO());
  localStorage.setItem(
    TIMERS_KEY,
    JSON.stringify(Array.from(timers.entries()))
  );
}

function clearTimer(id: string) {
  let timersJSON = localStorage.getItem(TIMERS_KEY);
  if (timersJSON) {
    const timers: Map<string, string> = new Map(JSON.parse(timersJSON));
    timers.delete(id);
    localStorage.setItem(
      TIMERS_KEY,
      JSON.stringify(Array.from(timers.entries()))
    );
  }
}

function isTimerExpired(id: string) {
  let isExpired = true;

  let activationTime: string | undefined;
  let timersJSON = localStorage.getItem(TIMERS_KEY);
  if (timersJSON) {
    const timers: Map<string, string> = new Map(JSON.parse(timersJSON));
    activationTime = timers.get(id);
  }

  if (activationTime) {
    isExpired =
      DateTime.local().diff(DateTime.fromISO(activationTime), "seconds")
        .seconds > TIMER_ACTIVE_IN_SECONDS;
  }
  return isExpired;
}
interface Props {
  workflow: WorkflowResponse;
}

export const useGeolocalizedWorkflow = (props: Props) => {
  const { workflow } = props;
  const { userLocationStore } = useStores();

  const gpsLocation = workflow.geoLocationCondition?.gpsLocation;
  const allowInside = workflow.geoLocationCondition?.allowInside;
  const allowOutside = workflow.geoLocationCondition?.allowOutside;

  let isGeolocationAuthorized = false;
  let userToLocationRadiusDistance: number | null = null;
  let userWasPreviouslyInRange = false;
  let userLocationObject = userLocationStore.userLocation;
  if (userLocationObject) {
    isGeolocationAuthorized = true;

    let userLocation = {
      latitude: userLocationObject.coords.latitude,
      longitude: userLocationObject.coords.longitude,
    };

    let location = {
      latitude: gpsLocation?.latitude!,
      longitude: gpsLocation?.longitude!,
    };

    const distanceInMeters = getDistance(userLocation, location);

    if (allowInside && allowOutside) {
      userToLocationRadiusDistance = 0;
    } else if (allowInside) {
      userToLocationRadiusDistance = Math.max(
        distanceInMeters - gpsLocation!.radius,
        0
      );
    } else if (allowOutside) {
      userToLocationRadiusDistance = Math.max(
        gpsLocation!.radius - distanceInMeters,
        0
      );
    }
    if (
      userToLocationRadiusDistance !== null &&
      userToLocationRadiusDistance === 0
    ) {
      // as long as you're in the zone, restart WorkflowActivationTimer to avoid displaying notification if the user
      // don't go outside the region
      saveTimerWithId(workflow.workflowId);
    } else {
      const isExpired = isTimerExpired(workflow.workflowId);
      userWasPreviouslyInRange = !isExpired;
      if (isExpired) {
        clearTimer(workflow.workflowId);
      }
    }
  }
  return {
    isGeolocationAuthorized,
    userWasPreviouslyInRange,
    userToLocationRadiusDistance,
  };
};
