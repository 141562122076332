import React from "react";

interface Props {
  message: string;
  className?: string;
}

export const ErrorLabel = (props: Props) => {
  return (
    <label
      className={
        "c-helper c-helper--invalid u-display-block " + (props.className ?? "")
      }
    >
      {props.message}
    </label>
  );
};
