import React from "react";
import { Page } from "./Page";
import { useTranslation } from "react-i18next";
import { Icon } from "../assets/img/Icon";

interface Props {}

export const ColumnPage = (props: React.PropsWithChildren<Props>) => {
  return (
    <Page>
      <div className={"l-container u-padding-h-outer u-max-width-500"}>
        <IconHeader />
        <div className="c-box u-padding-all-m@medium">{props.children}</div>
      </div>
    </Page>
  );
};

export const IconHeader = () => {
  const { t } = useTranslation("mobile");
  return (
    <div className="u-align-center u-margin-top-m u-margin-top-xl@medium u-margin-bottom-m u-flex u-flex-col u-flex-align-items-center">
      <Icon />
      <div
        className={"u-margin-top-gutter u-margin-bottom-none"}
        style={{ width: 250 }}
      >
        <text className="c-heading c-heading--l u-text-2xl@medium">
          {t("onboarding.welcome")}
        </text>
      </div>
    </div>
  );
};
