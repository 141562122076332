import React, { useEffect, useState } from "react";
import { QRScanner } from "../../../components/QRScanner";
import { useHistory, useLocation } from "react-router-dom";
import { useStores } from "../../../hooks/useStores";
import { WorkflowResult } from "../workflows/WorkflowResult";
import { WorkflowDetail } from "../workflows/WorkflowDetail";
import { WorkflowResponse } from "../../../services/models/WorkflowResponse";
import { useTranslation } from "react-i18next";
import { Title } from "../../../components/Title";
import { Button } from "../../../components/Button/Button";
import { PaddedPage } from "../../../components/PaddedPage";
import { ModalContainer } from "../../../components/ModalContainer";
import { RoutePath} from "../../Router";

const useScannerDetail = () => {
  const history = useHistory();
  const { proovrApi, analyticsStore } = useStores();
  const [isLoading, setIsLoading] = useState(false);
  const [workflow, setWorkflow] = useState<WorkflowResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { t } = useTranslation("mobile");

  const close = () => {
    history.replace(RoutePath.actions);
  };

  const processData = async (data: string | null) => {
    if (data !== null && !isLoading) {
      setIsLoading(true);
      try {
        const workflow = await proovrApi.getWorkflowFromUrl(data);
        setErrorMessage(null);
        setWorkflow(workflow);
        analyticsStore.post({ type: "scanning-succeeded" });
      } catch (e) {
        setWorkflow(null);
        setErrorMessage(t("workflow.invalid_workflow_exception"));
        analyticsStore.post({ type: "scanning-failed" });
      }
      setIsLoading(false);
    }
  };
  return { isLoading, workflow, errorMessage, processData, close };
};

export const ScannerDetail = (props: any) => {
  const { isLoading, workflow, errorMessage, processData, close } =
    useScannerDetail();
  const { t } = useTranslation("mobile");
  const { analyticsStore } = useStores();

  useEffect(() => {
    analyticsStore.post({
      type: "scanning-started",
    });
  }, []);

  useEffect(() => {
    (async () => {
      const workflowURL = props.location.state?.workflowURL;
      if (workflowURL) {
        await processData(workflowURL);
      }
    })();
  }, []);

  const Scanner = () => (
    <>
      <Title title={t("scanner.title")} />
      <div
        style={{
          position: "relative",
        }}
      >
        <QRScanner
          isLoading={isLoading}
          showViewFinder={workflow === null}
          setQRCodeData={(data) => processData(data)}
          onQrCodeOnError={(error) => {
            analyticsStore.post({
              type: "scanning-loaded",
              "authorized-camera": "false",
            });
          }}
        />
      </div>
      {workflow && !isLoading && (
        <WorkflowDetail workflow={workflow} onClose={close} />
      )}
      <Button onClick={close} className={"u-margin-top-m u-margin-bottom-m"}>
        {t("common.cancel")}
      </Button>
    </>
  );

  return (
    <PaddedPage className={"u-color-bg-neutral-off u-padding-top-m"}>
      {errorMessage === null && <Scanner />}
      {errorMessage && (
        <ModalContainer
          onClose={() => {
            close();
          }}
        >
          <WorkflowResult
            isSucceeded={false}
            resultMessage={errorMessage}
            onTap={close}
          />
        </ModalContainer>
      )}
    </PaddedPage>
  );
};
