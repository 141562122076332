import React, { useEffect, useState } from "react";
import Modal from "react-modal";

export interface Props {
  onClose(): void;
}

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.45)",
    zIndex: 1000,
  },
  content: {
    padding: 0,
    backgroundColor: "#f3f3f6",
    inset: 0,
    borderRadius: 0,
  },
};

export const ModalContainer = (props: React.PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <Modal isOpen={isOpen} style={modalStyles}>
      <div
        className={"u-flex u-flex-col u-flex-auto"}
        style={{ height: "100%" }}
      >
        <div
          className={
            "u-flex u-align-right u-padding-right-s u-padding-top-s u-flex-justify-end"
          }
        >
          <div
            onClick={() => {
              setIsOpen(false);
              props.onClose();
            }}
          >
            <svg className="c-icon__svg u-cursor-pointer">
              <use href={"#close-circle-24"}></use>
            </svg>
          </div>
        </div>
        {props.children}
      </div>
    </Modal>
  );
};
