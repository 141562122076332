import React from "react";

const SpinnerIcon = () => {
  return (
    <svg className="c-icon__svg">
      <use href={"#spinner-36"}></use>
    </svg>
  );
};

export const CenteredSpinner = () => {
  return (
    <div
      className={
        "u-flex-grow1 u-flex u-flex-align-items-center u-flex-justify-center"
      }
    >
      <span className="c-spinner c-spinner--l u-margin-v-l">
        <span className={"c-icon c-spinner__icon c-icon--36"}>
          <SpinnerIcon />
        </span>
      </span>
    </div>
  );
};

export const SpinnerWithBorder = () => {
  return (
    <div
      className={
        "u-flex u-flex-col u-flex-align-items-center u-flex-justify-center"
      }
    >
      <SpinnerIcon />
    </div>
  );
};
