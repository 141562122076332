import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "./hocs/useStore";
import "./index.scss";
import i18n from "i18next";
import { configure } from "mobx";
import { RootStore } from "./stores/RootStore";
import { initReactI18next, useTranslation } from "react-i18next";
import BackendAdapter from "i18next-multiload-backend-adapter";
import HttpBackend from "i18next-http-backend";
import Backend from "i18next-chained-backend";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./pages/Router";
import LanguageDetector from "i18next-browser-languagedetector";
import { CenteredSpinner } from "./components/Spinner/Spinner";
import { useConfigLoader } from "./hooks/useConfigLoader";
import LocalStorageBackend from "i18next-localstorage-backend";
import { Formatter } from "./common/Formatter"; // primary use cache
import { countryCode, userLanguageCode } from "./common/ApplicationInfo";
import { Helmet } from "react-helmet";

// don't allow state modifications outside actions
configure({
  enforceActions: "observed",
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
});

const Application = () => {
  const { config } = useConfigLoader();
  const formatter = new Formatter(userLanguageCode(), countryCode());

  if (config) {
    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(Backend)
      .init({
        load: "languageOnly",
        backend: {
          backends: [
            LocalStorageBackend,
            new BackendAdapter(null, {
              backend: new HttpBackend(null, {
                //{{lng}} returns lng+fallbackLng
                //{{ns}} returns all namespaces values
                loadPath:
                  config.ProovrApiBaseUrl +
                  "/v1/localization.json?languages={{lng}}&namespaces={{ns}}",
                allowMultiLoading: true,
                crossDomain: true,
              }),
            }),
          ],
          backendOptions: [
            { expirationTime: 7 * 24 * 60 * 60 * 1000 },
            {
              prefix: "i18next_res_",
              versions: {},
            },
          ],
        },
        fallbackLng: "en",
        ns: ["mobile", "workflows", "web"],
        interpolation: {
          escapeValue: false,
          format: (value, format, _) => formatter.interpolate(value, format),
        },
      })
      .then();

    const store = new RootStore(config);

    return (
      <React.StrictMode>
        <StoreProvider store={store}>
          <Suspense fallback={<CenteredSpinner />}>
            <BrowserRouter>
              <HeaderAndRouter />
            </BrowserRouter>
          </Suspense>
        </StoreProvider>
      </React.StrictMode>
    );
  } else {
    return <CenteredSpinner />;
  }
};

const HeaderAndRouter = () => {
  const { t } = useTranslation("web");
  return (
    <>
      <Helmet>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.description")} />
      </Helmet>
      <Router />
    </>
  );
};

ReactDOM.render(<Application />, document.getElementById("root"));
