export interface WorkflowResponse {
  workflowId: string;
  logoUrl: string;
  startUrl: string;
  // todo: verify if that works instead of requestedCredentials?: Map<string, string>;
  requestedCredentials?: { [key: string]: any };
  geoLocationCondition?: GeoLocationConditionResponse;

  notifications: WorkflowResponseNotifications;
  localizationKey: string;
  enabled: boolean;
  variables: { [key: string]: any };
}

export interface WorkflowResponseNotifications {
  enter: boolean;
  exit: boolean;
}

export interface GeoLocationConditionResponse {
  allowInside?: boolean;
  allowOutside?: boolean;
  gpsLocation?: GpsLocation;
}

export interface GpsLocation {
  latitude: number;
  longitude: number;
  radius: number;
}

export function isWorkflowResponse(
  workflow: WorkflowResponse
): workflow is WorkflowResponse {
  const w = workflow as WorkflowResponse;
  return w.workflowId !== undefined && w.startUrl !== undefined;
}
