import React from "react";
import { WorkflowResponse } from "../../../services/models/WorkflowResponse";
import { WorkflowCard } from "./WorkflowCard";
import { useTranslation } from "react-i18next";

interface Props {
  workflowResponse: WorkflowResponse;
}

export const useWorkflowCardDefault = (workflow: WorkflowResponse) => {
  const { t } = useTranslation("mobile");

  const translationBasePath = `workflows:${
    workflow.localizationKey ?? ""
  }.action.${workflow.enabled ? "enabled" : "disabled"}`;
  let translationInterpolations = workflow.variables;
  const name = t(translationBasePath + ".title", translationInterpolations);

  return {
    name,
    description: t(
      translationBasePath + ".subtitle",
      translationInterpolations
    ),
    isActive: workflow.enabled,
  };
};

export const WorkflowCardDefault = (props: Props) => {
  const { name, description, isActive } = useWorkflowCardDefault(
    props.workflowResponse
  );

  return (
    <WorkflowCard
      name={name}
      description={description}
      isActive={isActive}
      workflowResponse={props.workflowResponse}
    />
  );
};
