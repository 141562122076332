import React from "react";
import { Page } from "./Page";

interface Props {
  className?: string;
}

export const PaddedPage = (props: React.PropsWithChildren<Props>) => {
  return (
    <Page>
      <div
        className={
          "u-padding-right-m u-padding-left-m u-padding-top-m " +
            props.className ?? ""
        }
      >
        {props.children}
      </div>
    </Page>
  );
};
