import React from "react";

interface Props {
  width?: number;
}

export const Icon = (props: Props) => {
  return (
    <div>
      <svg
        style={{ width: "inherit", height: "inherit" }}
        width={props.width ?? 80}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="80" height="80" rx="8" fill="#0A0A47" />
        <path
          d="M19.4285 44.5714C19.4285 38.8908 24.0335 34.2857 29.7142 34.2857C35.3948 34.2857 39.9999 38.8908 39.9999 44.5714V58.2857C39.9999 63.9663 35.3948 68.5714 29.7142 68.5714C24.0335 68.5714 19.4285 63.9663 19.4285 58.2857V44.5714Z"
          fill="url(#paint0_linear)"
        />
        <g filter="url(#filter0_d)">
          <path
            d="M60.5713 28.6189C60.5713 38.7665 52.5848 45.8092 40.968 45.8092H34.2856C22.2856 45.8092 21.9999 48.4756 19.4285 51.4286V17.1429C19.4285 13.987 21.9868 11.4286 25.1428 11.4286H40.968C52.5848 11.4286 60.5713 18.6228 60.5713 28.6189Z"
            fill="url(#paint1_linear)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="9.42847"
            y="11.4286"
            width="61.1428"
            height="60"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters={"sRGB"}
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="29.7142"
            y1="34.2857"
            x2="29.7142"
            y2="68.5714"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#209664" />
            <stop offset="1" stopColor="#26DB8E" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="39.9999"
            y1="11.4286"
            x2="39.9999"
            y2="51.4286"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#26DB8E" />
            <stop offset="1" stopColor="#219A66" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
