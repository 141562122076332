import { WorkflowResponse } from "../../services/models/WorkflowResponse";

export interface Action {
  actionType: "scanner" | "geo" | "workflow";
}

export function isActionScanner(input: Action): input is ActionGeolocation {
  return input.actionType === "scanner";
}

export function isActionGeolocation(input: Action): input is ActionGeolocation {
  return input.actionType === "geo";
}

export function isActionWorkFlow(input: Action): input is ActionWorkFlow {
  return input.actionType === "workflow";
}

export interface ActionScanner extends Action {}

export interface ActionGeolocation extends Action {}

export interface ActionWorkFlow extends Action {
  workflowResponse: WorkflowResponse;
}
