export type ApplicationStorageKey =
  | "auth_jwt"
  | "analyticsUserId"
  | "workflowNavigationParameter"
  | "workflowURLNavigationParameter";

export class ApplicationStorage {
  public clear() {
    this.remove("auth_jwt");
    this.remove("workflowNavigationParameter");
    this.remove("workflowURLNavigationParameter");
  }

  public get(key: ApplicationStorageKey): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  }

  public set(value: string | null, key: ApplicationStorageKey) {
    try {
      if (value) {
        localStorage.setItem(key, value);
      } else {
        this.remove(key);
      }
    } catch (e) {
      console.log(e);
    }
  }

  public remove(key: ApplicationStorageKey) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      return null;
    }
  }
}
