import React, { PropsWithChildren } from "react";

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  buttonSize?: "s" | "m" | "l";
  outline?: boolean;
  type?: "submit" | "reset" | "button";
  className?: string;
  testId?: string;
}
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props: PropsWithChildren<ButtonProps>, ref) => {
    let className = "c-button c-button--fluid";
    if (props.size || props.buttonSize !== "m") {
      className += ` c-button--${props.buttonSize}`;
    }
    if (props.isLoading) {
      className += " is-loading";
    }

    if (!props.outline) {
      className += " c-button--primary";
    }
    if (props.className) {
      className += " " + props.className;
    }

    return (
      <button
        ref={ref}
        className={className}
        disabled={props.disabled}
        onClick={props.onClick}
        type={props.type}
        data-test-id={props.testId}
      >
        {props.isLoading && (
          <span className="c-spinner c-button__spinner">
            <svg className="c-icon__svg">
              <use href={"#spinner-24"}></use>
            </svg>
          </span>
        )}
        {props.children}
      </button>
    );
  }
);
